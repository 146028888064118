exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-check-inbox-tsx": () => import("./../../../src/pages/check-inbox.tsx" /* webpackChunkName: "component---src-pages-check-inbox-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-deposit-reward-tsx": () => import("./../../../src/pages/deposit-reward.tsx" /* webpackChunkName: "component---src-pages-deposit-reward-tsx" */),
  "component---src-pages-download-app-tsx": () => import("./../../../src/pages/download-app.tsx" /* webpackChunkName: "component---src-pages-download-app-tsx" */),
  "component---src-pages-email-confirmed-tsx": () => import("./../../../src/pages/email-confirmed.tsx" /* webpackChunkName: "component---src-pages-email-confirmed-tsx" */),
  "component---src-pages-get-early-access-tsx": () => import("./../../../src/pages/get-early-access.tsx" /* webpackChunkName: "component---src-pages-get-early-access-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-learning-hub-tsx": () => import("./../../../src/pages/learning-hub.tsx" /* webpackChunkName: "component---src-pages-learning-hub-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-our-clients-tsx": () => import("./../../../src/pages/our-clients.tsx" /* webpackChunkName: "component---src-pages-our-clients-tsx" */),
  "component---src-pages-rates-tsx": () => import("./../../../src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */),
  "component---src-pages-solutions-custody-tsx": () => import("./../../../src/pages/solutions/custody.tsx" /* webpackChunkName: "component---src-pages-solutions-custody-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-solutions-trading-tsx": () => import("./../../../src/pages/solutions/trading.tsx" /* webpackChunkName: "component---src-pages-solutions-trading-tsx" */),
  "component---src-pages-solutions-white-label-tsx": () => import("./../../../src/pages/solutions/white-label.tsx" /* webpackChunkName: "component---src-pages-solutions-white-label-tsx" */),
  "component---src-pages-waitlist-tsx": () => import("./../../../src/pages/waitlist.tsx" /* webpackChunkName: "component---src-pages-waitlist-tsx" */),
  "component---src-pages-welcome-bonus-tsx": () => import("./../../../src/pages/welcome-bonus.tsx" /* webpackChunkName: "component---src-pages-welcome-bonus-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

