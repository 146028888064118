import './src/styles/global.css';
import './src/styles/index.scss';

global.Buffer = global.Buffer || require('buffer').Buffer;

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `The latest Stablehouse update is available. Reload for an enhanced experience.`
  );

  if (answer === true) {
    window.location.reload(true);
  }
};
